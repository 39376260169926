body {
  /* Colour Pallete */
  --text-primary: #0c0c0c;
  --text-secondary: #373738;
  --accent-primary: #195790; /* prev #0c60a1 */
  --accent-secondary: #39c4c9;
  --pes-blue: #488fc7;
  --bg-primary: #e7edf1;
  --bg-secondary: #c8d5df;
  --success: #299d20;
  --warning: #dabc26;
  --error: #d91d1d;
  --info: #dcd7c2;

  --speed: 500ms;
}

p {
  color: var(--text-primary);
}

body {
  background-color: var(--bg-primary);
}

h2 {
  color: var(--text-primary);
  font-size: 28px;
}

h3 {
  color: var(--text-primary);
  font-size: 20px;
}

.app-container {
  max-width: 1000px;
  margin: 0px auto;
  padding: 0 10px;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.display-none {
  display: none !important;
}

.button1 {
  background-color: var(--accent-primary);
  color: var(--bg-primary);
  padding: 10px 20px;
  border-radius: 10px;
  border: none;
  appearance: none;
  font-size: 1.3rem;
  box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.12),
    0px 18px 88px -4px rgba(24, 39, 75, 0.14);
  transition: all ease-in 0.1s;
  cursor: pointer;
  opacity: 1;
}

.button1:hover {
  transform: scale(1.02);
  opacity: 0.9;
}

.button1:disabled {
  background-color: var(--text-secondary);
  opacity: 0.9;
  transform: scale(1);
}

.button2 {
  background-color: var(--accent-primary);
  color: var(--bg-primary);
  padding: 2px 10px;
  border-radius: 10px;
  border: none;
  appearance: none;
  font-size: 16px;
  box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.12),
    0px 18px 88px -4px rgba(24, 39, 75, 0.14);
  transition: all ease-in 0.1s;
  cursor: pointer;
  opacity: 1;
  font-weight: 600;
}

.button2:hover {
  transform: scale(1.02);
  opacity: 0.9;
}