.container {
    background-color: var(--bg-secondary);
    display: grid;
    grid-template-columns: 22px 30px 1fr 32px 105px;
    grid-template-rows: 18px;
    margin-bottom: 4px;
    padding: 3px 6px;
    border-radius: 4px;
    justify-content: space-between;
}

.position {
    grid-column-start: 1;
    grid-row-start: 1;
    font-size: 14px;
    font-weight: 900;
    color: var(--accent-primary);
}

.numName {
    grid-column: 2 / span 2;
    grid-row-start: 1;
}

.age {
    grid-column-start: 4;
    grid-row-start: 1;
    color: var(--text-secondary);
}

.time {
    grid-column-start: 5;
    grid-row-start: 1;
    text-align: right;
}

@media only screen and (max-width: 500px) {
    .container {
        grid-template-columns: 22px 40px 1fr 50px;
        grid-template-rows: 18px 18px;
    }

    .numName {
        grid-column: 2 / span 4;
        grid-row-start: 1;
    }

    .age {
        grid-column-start: 2;
        grid-row-start: 2;
    }
    .time {
        grid-row-start: 2;
    }
}