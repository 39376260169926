.container {
    background-color: var(--bg-secondary);
    border-radius: 8px;
    padding: 12px;
    margin-bottom: 6px;
    cursor: pointer;
    transition: all ease-in 0.1s;
}

.container:hover {
    opacity: 0.8;
}

.name {
    font-size: 20px;
    line-height: 18px;
    color: var(--text-primary);
    font-weight: 600;
    margin-bottom: 8px;
}

.time {
    font-size: 14px;
    color: var(--text-secondary);
}